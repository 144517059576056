<template>
  <div class="row">
    <div class="card">
      <div class="card-body row space-between">
        <div class="card-title">
          {{ title }}
          <i class="material-icons" style="float: right"> push_pin </i>
        </div>
        <div class="card-desc">{{ desc }}</div>
        <div v-if="loading" class="flex-center">
          <div class="spinner-border" role="status">
            <span class="visually-hidden" style="margin: auto">Loading...</span>
          </div>
        </div>
        <div v-else class="row space-between">
          <div class="col-xl-3 bl-card-body">
            <div class="month-title">
              {{ current_month }} {{ current_year }}
            </div>
            <div class="month-sub-title">
              <div class="timespan-container blue">
                {{ $t("dashboard.this", { text: "Month" }) }}
              </div>
            </div>
            <div class="stats-figure">
              {{ stats.this_month.toLocaleString() }}
            </div>
          </div>
          <div class="col-xl-3 bl-card-body">
            <div class="month-title">
              {{ previous_month }} {{ current_year }}
            </div>
            <div class="month-sub-title">
              <div class="timespan-container blue">
                {{ $t("dashboard.last", { text: "Month" }) }}
              </div>
            </div>
            <div class="stats-figure">
              {{ stats.last_month.toLocaleString() }}
            </div>
          </div>
          <div class="col-xl-3 bl-card-body">
            <div class="month-title">
              {{ current_quarter }} {{ current_year }}
            </div>
            <div class="month-sub-title">
              <div class="timespan-container orange">
                {{ $t("dashboard.this", { text: "Quarter" }) }}
              </div>
            </div>
            <div class="stats-figure">
              {{ stats.this_quarter.toLocaleString() }}
            </div>
          </div>
          <div class="col-xl-3 bl-card-body">
            <div class="month-title">
              {{ previous_quarter }} {{ current_year }}
            </div>
            <div class="month-sub-title">
              <div class="timespan-container orange">
                {{ $t("dashboard.last", { text: "Quarter" }) }}
              </div>
            </div>
            <div class="stats-figure">
              {{ stats.last_quarter.toLocaleString() }}
            </div>
          </div>
          <div class="col-xl-3 bl-card-body">
            <div class="month-title">January - December {{ current_year }}</div>
            <div class="month-sub-title">
              <div class="timespan-container green">
                {{ $t("dashboard.this_year") }}
              </div>
            </div>
            <div class="stats-figure">
              {{ stats.this_year.toLocaleString() }}
            </div>
          </div>

          <div class="col-xl-3 bl-card-body">
            <div class="month-title">
              January - December {{ previous_year }}
            </div>
            <div class="month-sub-title">
              <div class="timespan-container green">
                {{ $t("dashboard.last", { text: "Year" }) }}
              </div>
            </div>
            <div class="stats-figure">
              {{ stats.last_year.toLocaleString() }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { DateTime } from "luxon";
import { DATES } from "../constants/constants";

export default {
  name: "StatsCard",
  props: {
    title: String,
    desc: String,
    statsData: Object,
  },
  data() {
    return {
      stats: {
        this_month: 0,
        last_month: 0,
        this_quarter: 0,
        last_quarter: 0,
        this_year: 0,
        last_year: 0,
      },
      dates: DATES,
      loading: true,
    };
  },
  mounted() {
    this.stats = this.statsData;
    this.loading = false;
  },
  computed: {
    current_month: function () {
      return DateTime.local().monthLong;
    },
    current_year: function () {
      return DateTime.local().year;
    },
    previous_month: function () {
      return DateTime.local().minus({ month: 1 }).monthLong;
    },
    current_quarter: function () {
      let start_month = DateTime.local().startOf("quarter").monthLong;
      let end_month = DateTime.local().endOf("quarter").monthLong;
      return `${start_month} - ${end_month}`;
    },
    previous_quarter: function () {
      let start_month = DateTime.fromFormat(
        this.dates.last_quarter.start_date,
        "yyyy-MM-dd"
      ).monthLong;
      let end_month = DateTime.fromFormat(
        this.dates.last_quarter.end_date,
        "yyyy-MM-dd"
      ).monthLong;
      return `${start_month} - ${end_month}`;
    },
    previous_year: function () {
      return DateTime.local().minus({ year: 1 }).year;
    },
  },
};
</script>

<style scoped>
.bl-card-body {
  margin: 10px;
  padding: 20px;
  border-radius: 10px;
}

.bl-card-body:hover {
  box-shadow: none;
}

.month-title {
  font-weight: 500;
}

.month-sub-title {
  font-weight: 100;
  color: var(--font-color-secondary);
}

.stats-figure {
  font-size: 20px;
  font-weight: 600;
  padding-top: 10px;
}

.material-icons {
  color: var(--font-color-secondary);
  vertical-align: middle;
  margin-right: 5px;
  display: none;
}

.card-title:hover .material-icons {
  display: inline;
}

.material-icons:hover {
  color: white;
  cursor: pointer;
}

.card-desc {
  /* color: var(--font-color-secondary); */
  padding: 5px 10.5px;
}

.timespan-container {
  width: fit-content;
  padding: 5px 7px;
  border-radius: 5px;
  margin-top: 5px;
  font-weight: 400;
}

.green {
  background: rgba(75, 173, 72, 0.2);
  color: var(--utility-green);
}

.orange {
  color: var(--utility-orange);
  background: rgba(255, 149, 0, 0.2);
}

.blue {
  color: var(--utility-blue);
  background: rgba(34, 105, 245, 0.2);
}
</style>
